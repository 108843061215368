import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

export default new VueRouter({
/*	base:'/goujian/vue/',
    routes: [
        {
           path: '/projects',
            component: () => import( '../components/projects.vue')
         }   
    ]*/
    routes: [
        {
           path: '/',
            component: () => import( '../components/projects.vue')
         }   
    ]
   
});

