import Vue from 'vue'
import App from './App.vue'
import router from './router';
import axios from 'axios';
Vue.config.productionTip = false
Vue.prototype.$ajax=axios;
axios.defaults.timeout=10000;
new Vue({
	 router,
  render: h => h(App)
}).$mount('#app')
